import Vue from 'vue';
import Component from 'vue-class-component';

import B2BRegistrationDialog from './B2BRegistrationDialog.vue';

@Component
export default class B2BLanding extends Vue {
  // props

  // data()

  // computed

  // methods
  openDialog() {
    this.$dialog.open({
      component: B2BRegistrationDialog,
    });
  }

  scrollToTop() {
    window.scroll(0, 0);
  }
}
