



































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  popupOptions: {
    size: 'xs',
  },
})
export default class B2BRegistrationCompleteDialog extends Vue {
  // props
  // props end
  // data()
  // data() end
  // lifecycle hooks
  // methods
}
