var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__body border-bottom text-center p-3" },
      [
        _c("app-dialog-close"),
        _c("h5", { staticClass: "mb-3 text-center" }, [
          _vm._v("\n      Тариф успешно активирован\n    ")
        ]),
        _c("p", { staticClass: "m-0" }, [
          _vm._v(
            "\n      Теперь вам доступны отчеты ЕГРН об объекте и Переходе прав по 25 руб./шт.\n    "
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body p-3" },
      [
        _c("h6", { staticClass: "text-center" }, [
          _vm._v("\n      С чего начать\n    ")
        ]),
        _vm._m(0),
        _c(
          "app-button",
          {
            attrs: { block: "" },
            on: {
              click: function($event) {
                return _vm.$dialog.close()
              }
            }
          },
          [_vm._v("\n      Продолжить\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "list-unstyled" }, [
      _c("li", { staticClass: "py-1" }, [
        _vm._v("\n        1. Воспользуйтесь строкой поиска\n      ")
      ]),
      _c("li", { staticClass: "py-1" }, [
        _vm._v(
          "\n        2. Введите необходимый вам адрес или кадастровый номер объекта\n      "
        )
      ]),
      _c("li", { staticClass: "py-1" }, [
        _vm._v("\n        3. Выберите необходимые выписки\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }