var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", [_vm._v("Активация тарифа «ЕГРН за 25 руб.»")]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [
                      _vm._v(
                        "\n        Активировать и зарегистрироваться\n      "
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { label: "Ваш email", "model-name": "email" } },
            [
              _c("app-input", {
                attrs: { type: "email", placeholder: "email..." },
                model: {
                  value: _vm.model.email,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "email", $$v)
                  },
                  expression: "model.email"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { label: "Номер телефона", "model-name": "phone" } },
            [
              _c("app-phone-number-input", {
                model: {
                  value: _vm.model.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "phone", $$v)
                  },
                  expression: "model.phone"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { label: "Роль", "model-name": "person_role" } },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.model.person_role,
                      expression: "model.person_role",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "custom-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return _vm._n(val)
                        })
                      _vm.$set(
                        _vm.model,
                        "person_role",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.roles, function(roleName, roleId) {
                  return _c(
                    "option",
                    { key: roleId, domProps: { value: roleId } },
                    [_vm._v("\n          " + _vm._s(roleName) + "\n        ")]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }