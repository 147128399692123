






















































import Vue from 'vue';
import Component from 'vue-class-component';
import omit from 'lodash/omit';

import { Validators } from '@/core/utils/validator';
import { RoleNames, Role } from '@/core/utils/constants';

import B2BRegistrationCompleteDialog from './B2BRegistrationCompleteDialog.vue';

@Component({
  popupOptions: {
    size: 'xs',
  },
})
export default class B2BRegistrationDialog extends Vue {
  // props

  // props end

  // data()
  roles: SimpleObject<string> = {
    ...omit(RoleNames, Role.Admin, Role.Client),
    [Role.Client]: 'Другое',
  };
  model: SimpleObject<string | number> = {
    email: '',
    phone: '',
    person_role: Role.Realtor,
  };

  // data() end

  // methods
  async onSubmit() {
    try {
      this.$store.commit('marketing/setCampaign', '9rubles');
      this.$dialog.close();
      this.$router.push({ name: 'home' });
      this.$dialog.open({
        component: B2BRegistrationCompleteDialog,
      });
    } catch (error) {
      const text = (error as Error).message;
      this.$noty.error({ text });
      console.error(error);
    }
  }

  validationRules(): ValidationConfig {
    return {
      email: [ Validators.required, Validators.email ],
      phone: [ Validators.required, Validators.phone ],
      person_role: [ Validators.required ],
    };
  }
}
